import { Link } from 'gatsby';
import React, { ReactElement } from 'react';

import './PartnerSignUpWidget.scss';

interface Props {
  data: {
    widgetHeader: string;
    widgetText: string;
    buttonText: string;
    buttonLink?: string;
    externalLink: boolean;
  };
}

const PartnerSignUpWidget = (props: Props): ReactElement => {
  const {
    widgetHeader,
    widgetText,
    buttonText,
    buttonLink,
    externalLink,
  } = props.data;

  return (
    <div className="partnerSignUpContainer">
      <h2>{widgetHeader}</h2>
      <p>{widgetText}</p>
      <div>
        <button className="btn" type="button">
          {!externalLink ? (
            <Link to="/new-partner/join">{buttonText}</Link>
          ) : (
            <a href={buttonLink}>{buttonText}</a>
          )}
        </button>
      </div>
    </div>
  );
};

export default PartnerSignUpWidget;
