import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import parse from 'react-html-parser';

import PartnerPagesLayout from '../../components/Layout/PartnerPagesLayout';
import GeneralHeroSec from '@motokiki/shared/generalHeroSec';
import Expandable from '@motokiki/shared/expandable';
import { Helmet } from "react-helmet";
import GeneralTextBlock from '@/components/GeneralTextBlock';
import PartnerWidget from '@/components/PartnerSignUpWidget';

import './Partners.scss';

interface Props {
  data: {
    strapi: {
      partnerpage: {
        id: string;
        Content: {
          map: (component) => any;
          __typename: string;
        };
        HeroSection: {
          activeLozenge: string;
          className: string;
          displayIcon: string;
          headerPrefix: string;
          headerText: string;
          lozengeText: string;
          mainImg: {
            url: string;
          };
          iconImg: {
            url: string;
          };
        };
        partner_pages_frequently_asked_questions: [{
          Question: string;
          Answer: string;
        }];
        title: string;
      };
    };
  };
}

const Partners = ({ data }: Props) : ReactElement => {
  const { partnerpage } = data.strapi;

  return (
    <PartnerPagesLayout>
      <GeneralHeroSec data={partnerpage.HeroSection} />
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="partnersPageContainer">
        {partnerpage.Content.map((component, i) => {
          return (
            <div key={i}>
              {component.__typename === 'strapi_ComponentGeneralTextBlock' && (
                <GeneralTextBlock key={i} textData={component} />
              )}

              {component.__typename ===
                'strapi_ComponentGeneralPartnerPageWidget' && (
                <PartnerWidget key={i} data={component} />
              )}
            </div>
          );
        })}

        <div className="faqContainer">
          {partnerpage.partner_pages_frequently_asked_questions.map(
            (faqItem, id) => (            
                <div className="faqItems" key={id}>
                  <Expandable title={faqItem.Question} height="auto">
                    {parse(faqItem.Answer)}
                  </Expandable>
                </div>                        
              ))
          }
        </div>
      </div>
    </PartnerPagesLayout>
  );
};

export const pageQuery = graphql`
  query($id: ID!) {
    strapi {
      partnerpage(id: $id, publicationState: PREVIEW) {
        id
        Content {
          __typename
          ... on strapi_ComponentGeneralTextBlock {
            id
            textBlock
          }
          ... on strapi_ComponentGeneralPartnerPageWidget {
            id
            buttonText
            widgetHeader
            widgetText
            buttonLink
            externalLink
          }
        }
        HeroSection {
          activeLozenge
          className
          displayIcon
          headerPrefix
          headerText
          lozengeText
          mainImg {
            url
          }
          iconImg {
            url
          }
        }
        partner_pages_frequently_asked_questions {
          Question
          Answer
        }
        title
      }
    }
  }
`;

export default Partners;
